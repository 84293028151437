var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ready)?_c('div',[(_vm.alert)?_c('div',{staticClass:"columns is-mobile is-variable is-12 is-multiline"},[_c('div',{staticClass:"column is-12 alert-info"},[_c('h1',[_vm._v("Formularz został wysłany.")]),(_vm.infoAfterSubmit && _vm.infoAfterSubmit.is_active)?_c('p',[_vm._v(" "+_vm._s(_vm.infoAfterSubmit.content)+" ")]):_vm._e(),_c('button',{staticClass:"button is-medium is-primary",on:{"click":function($event){_vm.alert = !_vm.alert}}},[_vm._v(" zamknij ")])])]):_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('form',{staticClass:"login",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitScroll.apply(null, arguments)}}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"ym-toptitle-box"},[_c('fixed-header',{attrs:{"threshold":0,"fixedClass":"ym-toptitle-fixed"}},[_c('div',{staticClass:"level ym-toptitle",attrs:{"id":"toptitle"}},[_c('div',{staticClass:"level-left"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.formSettings.name))])]),_c('div',{staticClass:"buttons level-right"},[(_vm.formSettings && _vm.formSettings.calendar_code !== '')?_c('button',{staticClass:"button is-medium is-secondary",attrs:{"type":"button"},on:{"click":function($event){_vm.isModalActive = !_vm.isModalActive}}},[_vm._v(" kalendarz serwisowy ")]):_vm._e()])])])],1),(_vm.inputs && _vm.inputs.length > 0)?[_vm._l((_vm.inputs),function(input){return [(input.field_type === 'input' && input.is_active)?[_c('div',{key:input.id,class:errors[input.field_name] &&
                  errors[input.field_name].length > 0
                    ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                    : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v(_vm._s(input.name))])])]),_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icons-right is-clearfix"},[_c('ValidationProvider',{attrs:{"rules":input.is_required ? 'required' : '',"name":input.field_name}},[(
                            _vm.isBApszczyna &&
                            input.field_name === 'customer_email'
                          )?[_c('vue-simple-suggest',{attrs:{"name":input.field_name,"display-attribute":"email","value-attribute":"email","list":_vm.getEmailSuggestionList,"min-length":3,"debounce":500},on:{"select":_vm.onSuggestSelect},scopedSlots:_vm._u([{key:"suggestion-item",fn:function(scope){return _c('div',{},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(scope.suggestion.email + " - " + scope.suggestion.firstname + " " + scope.suggestion.lastname + " " + scope.suggestion.phone)+" ")])])}}],null,true),model:{value:(_vm.form[input.field_name]),callback:function ($$v) {_vm.$set(_vm.form, input.field_name, $$v)},expression:"form[input.field_name]"}})]:(
                            _vm.isBApszczyna &&
                            input.field_name === 'customer_phone'
                          )?[_c('vue-simple-suggest',{attrs:{"name":input.field_name,"display-attribute":"phone","value-attribute":"phone","list":_vm.getPhoneSuggestionList,"min-length":3,"debounce":500},on:{"select":_vm.onSuggestSelect},scopedSlots:_vm._u([{key:"suggestion-item",fn:function(scope){return _c('div',{},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(scope.suggestion.phone + " - " + scope.suggestion.firstname + " " + scope.suggestion.lastname + " " + scope.suggestion.email)+" ")])])}}],null,true),model:{value:(_vm.form[input.field_name]),callback:function ($$v) {_vm.$set(_vm.form, input.field_name, $$v)},expression:"form[input.field_name]"}})]:[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[input.field_name]),expression:"form[input.field_name]"}],key:_vm.inputKey,staticClass:"input is-medium",attrs:{"name":input.field_name},domProps:{"value":(_vm.form[input.field_name])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, input.field_name, $event.target.value)}}})]],2),(
                          errors[input.field_name] &&
                          errors[input.field_name].length > 0
                        )?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[input.field_name][0]))]):_vm._e(),(
                          input.field_placeholder !== '' &&
                          !_vm.form[input.field_name]
                        )?_c('b-notification',{attrs:{"type":"is-info is-light placeholder","closable":false}},[_vm._v(_vm._s(input.field_placeholder))]):_vm._e()],1)])])])]:_vm._e(),(input.field_type === 'textarea' && input.is_active)?[_c('div',{key:input.id,class:errors[input.field_name] &&
                  errors[input.field_name].length > 0
                    ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                    : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v(_vm._s(input.name))])])]),_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icons-right is-clearfix"},[_c('ValidationProvider',{attrs:{"rules":input.is_required ? 'required' : '',"name":input.field_name}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[input.field_name]),expression:"form[input.field_name]"}],key:input.id,staticClass:"input is-medium",attrs:{"name":input.field_name},domProps:{"value":(_vm.form[input.field_name])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, input.field_name, $event.target.value)}}})]),(
                          errors[input.field_name] &&
                          errors[input.field_name].length > 0
                        )?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[input.field_name][0]))]):_vm._e(),(
                          input.field_placeholder !== '' &&
                          !_vm.form[input.field_name]
                        )?_c('b-notification',{attrs:{"type":"is-info is-light placeholder","closable":false}},[_vm._v(_vm._s(input.field_placeholder))]):_vm._e()],1)])])])]:_vm._e(),(input.field_type === 'checkbox' && input.is_active)?[_c('div',{key:input.id,class:errors[input.field_name] &&
                  errors[input.field_name].length > 0
                    ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                    : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v(_vm._s(input.name))])])]),_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icons-right is-clearfix"},[_c('ValidationProvider',{attrs:{"rules":input.is_required ? 'required' : '',"name":input.field_name}},[_c('b-checkbox',{key:input.id,attrs:{"name":input.field_name,"size":"is-medium","native-value":1},model:{value:(_vm.form[input.field_name]),callback:function ($$v) {_vm.$set(_vm.form, input.field_name, $$v)},expression:"form[input.field_name]"}},[_vm._v(_vm._s(input.field_values))])],1),(
                          errors[input.field_name] &&
                          errors[input.field_name].length > 0
                        )?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[input.field_name][0]))]):_vm._e(),(
                          input.field_placeholder !== '' &&
                          !_vm.form[input.field_name]
                        )?_c('b-notification',{attrs:{"type":"is-info is-light placeholder","closable":false}},[_vm._v(_vm._s(input.field_placeholder))]):_vm._e()],1)])])])]:_vm._e(),(input.field_type === 'radio' && input.is_active)?[_c('div',{key:input.id,class:errors[input.field_name] &&
                  errors[input.field_name].length > 0
                    ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                    : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v(_vm._s(input.name))])])]),_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"ym-rowbor"},[_c('div',{staticClass:"level ym-rowbor-item"},[_c('div',{staticClass:"ym-radios"},_vm._l((input.field_values),function(name,index){return _c('label',{key:index},[_c('ValidationProvider',{attrs:{"rules":input.is_required ? 'required' : '',"name":input.field_name}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[input.field_name]),expression:"form[input.field_name]"}],attrs:{"type":"radio","name":input.field_name},domProps:{"value":name,"checked":_vm._q(_vm.form[input.field_name],name)},on:{"change":function($event){return _vm.$set(_vm.form, input.field_name, name)}}}),_c('span',[_vm._v(_vm._s(name))])])],1)}),0)]),(
                        errors[input.field_name] &&
                        errors[input.field_name].length > 0
                      )?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[input.field_name][0]))]):_vm._e(),(
                        input.field_placeholder !== '' &&
                        !_vm.form[input.field_name]
                      )?_c('b-notification',{attrs:{"type":"is-info is-light placeholder","closable":false}},[_vm._v(_vm._s(input.field_placeholder))]):_vm._e()],1)])])]:_vm._e(),(input.field_type === 'select' && input.is_active)?[_c('div',{key:input.id,class:errors[input.field_name] &&
                  errors[input.field_name].length > 0
                    ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                    : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v(_vm._s(input.name))])])]),_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icons-right is-clearfix"},[_c('ValidationProvider',{attrs:{"rules":input.is_required ? 'required' : '',"name":input.field_name}},[_c('multiselect',{key:input.id,attrs:{"name":input.field_name,"options":input.field_values,"placeholder":"Wybierz"},model:{value:(_vm.form[input.field_name]),callback:function ($$v) {_vm.$set(_vm.form, input.field_name, $$v)},expression:"form[input.field_name]"}})],1),(
                          errors[input.field_name] &&
                          errors[input.field_name].length > 0
                        )?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[input.field_name][0]))]):_vm._e(),(
                          input.field_placeholder !== '' &&
                          !_vm.form[input.field_name]
                        )?_c('b-notification',{attrs:{"type":"is-info is-light placeholder","closable":false}},[_vm._v(_vm._s(input.field_placeholder))]):_vm._e()],1)])])])]:_vm._e(),(input.field_type === 'multiselect' && input.is_active)?[_c('div',{key:input.id,class:errors[input.field_name] &&
                  errors[input.field_name].length > 0
                    ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                    : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v(_vm._s(input.name))])])]),_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icons-right is-clearfix"},[_c('ValidationProvider',{attrs:{"rules":input.is_required ? 'required' : '',"name":input.field_name}},[_c('multiselect',{key:input.id,attrs:{"name":input.field_name,"options":input.field_values,"multiple":true,"placeholder":"Wybierz, możesz kilka"},model:{value:(_vm.form[input.field_name]),callback:function ($$v) {_vm.$set(_vm.form, input.field_name, $$v)},expression:"form[input.field_name]"}})],1),(
                          errors[input.field_name] &&
                          errors[input.field_name].length > 0
                        )?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[input.field_name][0]))]):_vm._e(),(
                          input.field_placeholder !== '' &&
                          !_vm.form[input.field_name]
                        )?_c('b-notification',{attrs:{"type":"is-info is-light placeholder","closable":false}},[_vm._v(_vm._s(input.field_placeholder))]):_vm._e()],1)])])])]:_vm._e(),(input.field_type === 'datepicker' && input.is_active)?[_c('div',{key:input.id,class:errors[input.field_name] &&
                  errors[input.field_name].length > 0
                    ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                    : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v(_vm._s(input.name))])])]),_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icons-right is-clearfix"},[_c('ValidationProvider',{attrs:{"rules":input.is_required ? 'required' : '',"name":input.field_name}},[_c('b-datepicker',{attrs:{"mobile-native":false,"name":input.field_name,"icon":"calendar-today","first-day-of-week":1,"position":"is-top-right","trap-focus":""},model:{value:(_vm.form[input.field_name]),callback:function ($$v) {_vm.$set(_vm.form, input.field_name, $$v)},expression:"form[input.field_name]"}})],1),(
                          errors[input.field_name] &&
                          errors[input.field_name].length > 0
                        )?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[input.field_name][0]))]):_vm._e(),(
                          input.field_placeholder !== '' &&
                          !_vm.form[input.field_name]
                        )?_c('b-notification',{attrs:{"type":"is-info is-light placeholder","closable":false}},[_vm._v(_vm._s(input.field_placeholder))]):_vm._e()],1)])])])]:_vm._e(),(input.field_type === 'datetimepicker' && input.is_active)?[_c('div',{key:input.id,class:errors[input.field_name] &&
                  errors[input.field_name].length > 0
                    ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                    : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v(_vm._s(input.name))])])]),_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icons-right is-clearfix"},[_c('ValidationProvider',{attrs:{"rules":input.is_required ? 'required' : '',"name":input.field_name}},[_c('b-datetimepicker',{attrs:{"mobile-native":false,"name":input.field_name,"icon":"calendar-today","first-day-of-week":1,"position":"is-top-right","trap-focus":""},model:{value:(_vm.form[input.field_name]),callback:function ($$v) {_vm.$set(_vm.form, input.field_name, $$v)},expression:"form[input.field_name]"}})],1),(
                          errors[input.field_name] &&
                          errors[input.field_name].length > 0
                        )?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[input.field_name][0]))]):_vm._e(),(
                          input.field_placeholder !== '' &&
                          !_vm.form[input.field_name]
                        )?_c('b-notification',{attrs:{"type":"is-info is-light placeholder","closable":false}},[_vm._v(_vm._s(input.field_placeholder))]):_vm._e()],1)])])])]:_vm._e(),(input.field_type === 'timepicker' && input.is_active)?[_c('div',{key:input.id,class:errors[input.field_name] &&
                  errors[input.field_name].length > 0
                    ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                    : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v(_vm._s(input.name))])])]),_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icons-right is-clearfix"},[_c('ValidationProvider',{attrs:{"rules":input.is_required ? 'required' : '',"name":input.field_name}},[_c('b-timepicker',{attrs:{"mobile-native":false,"name":input.field_name,"icon":"calendar-today","first-day-of-week":1,"position":"is-top-right","trap-focus":""},model:{value:(_vm.form[input.field_name]),callback:function ($$v) {_vm.$set(_vm.form, input.field_name, $$v)},expression:"form[input.field_name]"}})],1),(
                          errors[input.field_name] &&
                          errors[input.field_name].length > 0
                        )?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[input.field_name][0]))]):_vm._e(),(
                          input.field_placeholder !== '' &&
                          !_vm.form[input.field_name]
                        )?_c('b-notification',{attrs:{"type":"is-info is-light placeholder","closable":false}},[_vm._v(_vm._s(input.field_placeholder))]):_vm._e()],1)])])])]:_vm._e(),(input.field_type === 'file' && input.is_active)?[_c('div',{key:input.id,class:errors[input.field_name] &&
                  errors[input.field_name].length > 0
                    ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                    : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v(_vm._s(input.name))])])]),_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icons-right is-clearfix"},[_c('ValidationProvider',{attrs:{"rules":input.is_required ? 'required' : '',"name":input.field_name}},[_c('b-upload',{key:input.id,staticClass:"file-label",on:{"input":function($event){return _vm.fileToBase64($event, input.field_name)}},model:{value:(_vm.temp[`${input.field_name}_temp`]),callback:function ($$v) {_vm.$set(_vm.temp, `${input.field_name}_temp`, $$v)},expression:"temp[`${input.field_name}_temp`]"}},[_c('span',{staticClass:"file-cta"},[_c('span',{staticClass:"file-label"},[_vm._v("Dodaj plik")])]),(_vm.temp[`${input.field_name}_temp`])?_c('span',{staticClass:"file-name"},[_vm._v(" "+_vm._s(_vm.temp[`${input.field_name}_temp`].name)+" ")]):_vm._e()])],1),(
                          errors[input.field_name] &&
                          errors[input.field_name].length > 0
                        )?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[input.field_name][0]))]):_vm._e(),(
                          input.field_placeholder !== '' &&
                          !_vm.form[input.field_name]
                        )?_c('b-notification',{attrs:{"type":"is-info is-light placeholder","closable":false}},[_vm._v(_vm._s(input.field_placeholder))]):_vm._e()],1)])])])]:_vm._e(),(input.field_type === 'multifile' && input.is_active)?[_c('div',{key:input.id,class:errors[input.field_name] &&
                  errors[input.field_name].length > 0
                    ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                    : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_full"}},[_vm._v(_vm._s(input.name))])])]),_c('div',{staticClass:"column ym-column is-12"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control has-icons-right is-clearfix"},[_c('ValidationProvider',{attrs:{"rules":input.is_required ? 'required' : '',"name":input.field_name}},[_c('b-upload',{key:input.id,staticClass:"file-label",staticStyle:{"margin-bottom":"1rem"},attrs:{"multiple":"","drag-drop":""},on:{"input":function($event){return _vm.fileToBase64($event, input.field_name)}},model:{value:(_vm.temp[`${input.field_name}_temp`]),callback:function ($$v) {_vm.$set(_vm.temp, `${input.field_name}_temp`, $$v)},expression:"temp[`${input.field_name}_temp`]"}},[_c('section',{staticClass:"section",staticStyle:{"padding":"3rem"}},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{staticStyle:{"position":"relative"},attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v(" Upuść pliki tutaj lub kliknij, aby przesłać ")])])])]),(_vm.temp[`${input.field_name}_temp`])?_c('div',{staticClass:"tags"},_vm._l((_vm.temp[
                              `${input.field_name}_temp`
                            ]),function(file,index){return _c('span',{key:index,staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(file.name)+" "),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDropFile(
                                  `${input.field_name}_temp`,
                                  index,
                                  `${input.field_name}`
                                )}}})])}),0):_vm._e()],1),(
                          errors[input.field_name] &&
                          errors[input.field_name].length > 0
                        )?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[input.field_name][0]))]):_vm._e(),(
                          input.field_placeholder !== '' &&
                          !_vm.form[input.field_name]
                        )?_c('b-notification',{attrs:{"type":"is-info is-light placeholder","closable":false}},[_vm._v(_vm._s(input.field_placeholder))]):_vm._e()],1)])])])]:_vm._e()]})]:_vm._e(),_c('div',{staticClass:"level ym-toptitle",staticStyle:{"margin-top":"48px"}},[_c('div',{staticClass:"level-left"},[_vm._v(" ")]),_c('div',{staticClass:"buttons level-right"},[_c('button',{staticClass:"button is-medium is-primary"},[_c('icon',{attrs:{"name":"check"}}),_c('span',[_vm._v("Wyślij")])],1)])])],2)]),(_vm.formSettings && _vm.formSettings.calendar_code !== '')?_c('b-modal',{attrs:{"width":720,"scroll":"keep"},model:{value:(_vm.isModalActive),callback:function ($$v) {_vm.isModalActive=$$v},expression:"isModalActive"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content content-iframe",domProps:{"innerHTML":_vm._s(_vm.formSettings.calendar_code)}})])])]):_vm._e()]}}],null,false,2396924636)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }